import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'

const updateActivityRelevancySettingsMutation = gql`
    mutation updateActivityRelevancySettings(
        $tenantGuid: String!,
        $inputs:[UpdateActivityRelevancySettingInput]!
    ) {
        updateActivityRelevancySettings(
            tenantGuid: $tenantGuid,
            inputs: $inputs
        ) {
            key
            value
            label
        }
    }
`

export default function useUpdateFocusedSettingsMutation() {
    return useMutation(updateActivityRelevancySettingsMutation)
}
