import React from 'react'

const DatabaseIcon = () => (
    <svg height="32" width="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <g strokeLinecap="round" strokeWidth="1.2" transform="translate(0.5 0.5)">
            <path d="M3,5v22c0,2.3,6.1,4,13,4s13-1.7,13-4 V5" fill="none" />
            <path d="M29,5 c0,2.3-6.1,4-13,4S3,7.3,3,5s6.1-4,13-4S29,2.7,29,5z" fill="none" />
            <path d="M29,16c0,2.3-6.1,4-13,4 S3,18.3,3,16" fill="none" />
        </g>
    </svg>
)
export default DatabaseIcon
