import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { endOfToday, subDays } from 'date-fns'
import { hoursPerDay } from '../../../util/tools'
import { formatDate, formatDateString } from '../../../util/dates'
import TenantDetailsSubscriptionSection from './TenantDetailsSubscriptionSection'

const TENANT_DETAILS_QUERY = gql`
    query tenantDetailQuery (
        $currentPage: Int!,
        $pageSize: Int!,
        $where: TenantWhereInput,
        $syncWhere: SyncLogWhereInput,
        $statisticsWhere: ActivityWhereInput,
        $tenantGuid: String!,
    ) {
        tenantsPaginated (
            currentPage: $currentPage,
            pageSize: $pageSize,
            where: $where
        ) {
            nodes {
                id
                guid
                name
                adminAccountState
                isCalendarSyncActive
                isCallRecordSyncActive
                isSentMailsSyncActive
                isAvailable
                dataFactoryPipelineCreated
                stripeCustomerId
                stripeTaxId
                subscription {
                    plan {
                        id
                        name
                        priceInCents
                        product {
                            id
                            slug
                            stripeId
                        }
                    }
                    quantity
                    stripeStatus
                    trialEnd
                    allowSecibNeoReporting
                }
                currentPaymentMethod {
                    created
                    expirationMonth
                    expirationYear
                    type
                }
                tenantUsers {
                    accountStateSlug
                    user {
                        id
                        signUpName
                        signUpFirstName
                        loginEmail
                        termsAndConditionsAcceptedOn
                    }
                }
                dataImports {
                    id
                    guid
                    authorEmail
                    fileName
                    reverted
                    createdOn
                }
            }
          }
        activityRelevancySettings(
            tenantGuid: $tenantGuid,
        ) {
            key
            value
            label
            example
            dataTypeSlug
            typeSlug
            userId
        }
        syncLogSummaries(
            tenantGuid: $tenantGuid
        ) {
            externalApplicationId
            initialSyncStart
            initialSyncEnd
            processedUnits
            totalUnits
            entitiesPerUnit
            status
            key
            totalCount
        }
        syncAnalytics(
            tenantGuid: $tenantGuid,
            where: $syncWhere
          ) {
            perKey {
              key
              perDay {
                date
                processedEntities
                perHour {
                    hour
                    processedEntities
                }
              }
            }
        }
        activityStatistics(
            tenantGuid: $tenantGuid,
            where: $statisticsWhere
        ) {
            perPartner {
              partnerId
              partner {
                  fullName
              }
              perMLStatus {
                status
                count
                perType {
                    type {
                      slug
                    }
                    count
                }
              }
            }
            perCreationDay {
                creationDate
                perMLStatus {
                  status
                  count
                  perType {
                      type {
                        slug
                      }
                      count
                  }
                }
              }
        }
        allProducts {
            ...TenantDetailsSubscriptionSection
        }
    }
    ${TenantDetailsSubscriptionSection.FRAGMENT}
`

const useFetchTenantDetailsPageData = (tenantGuid) => {
    const dates = [endOfToday()]
    for (let i = 1; i < 7; i += 1) {
        dates.push(subDays(dates[0], i))
    }
    const variables = {
        pageSize: 1,
        currentPage: 1,
        where: {
            guids: [tenantGuid],
        },
        syncWhere: {
            from: dates[6],
            till: dates[0],
        },
        statisticsWhere: {
            startDateTime: dates[6],
            endDateTime: dates[0],
        },
        tenantGuid,
    }
    const {
        data = {},
        error,
        loading,
    } = useQuery(TENANT_DETAILS_QUERY, {
        variables,
        fetchPolicy: 'cache-and-network',
    })

    const refetchQueries = [{ query: TENANT_DETAILS_QUERY, variables }]

    if (loading) {
        return {
            isFetching: loading,
            error,
            analyticsKeys: [],
            focusedSettings: [],
            syncAnalytics: [],
            analyticsPerDay: [],
            statisticsPerPartner: [],
            refetchQueries,
        }
    }

    let analyticsKeys = []
    const analyticsPerDay = data.syncAnalytics.perKey.reduce((arr, { key, perDay }, index) => {
        analyticsKeys.push(key)
        perDay.forEach(({ date, processedEntities, perHour }) => {
            const formattedDate = formatDateString(date)
            let dayAnalytics = arr.find((item) => formattedDate === item.date)
            if (!dayAnalytics) {
                dayAnalytics = { date: formattedDate, perHour: [], [key]: processedEntities }
                arr.push(dayAnalytics)
            } else {
                dayAnalytics[key] = processedEntities
            }
            hoursPerDay.forEach((hour, index) => {
                let hourAnalytics = dayAnalytics.perHour.find((item) => hour === item.hour)
                const result = perHour.find((item) => item.hour === index)
                if (!hourAnalytics) {
                    hourAnalytics = { hour }
                    dayAnalytics.perHour.push(hourAnalytics)
                }
                hourAnalytics[key] = result ? result.processedEntities : 0
            })
        })
        return arr
    }, [])
    const analyticsPerDayWithEmptyDays = dates.map((date) => {
        const result = analyticsPerDay.find((anaylytics) => formatDate(date) === anaylytics.date)
        if (result) return result
        return {
            date: formatDate(date),
            perHour: []
        }
    })

    const statisticsPerPartner = data.activityStatistics.perPartner.reduce((arr, { partnerId, partner, perMLStatus }) => ([
        ...arr,
        {
            partner: `${partner.fullName} (id:${partnerId})`,
            ...perMLStatus.reduce((acc, item) => {
                acc[item.status] = item.count
                acc[`${item.status}PerType`] = item.perType.map(({ type, count }) => ({ slug: type.slug, count }))
                return acc
            }, {}),
        },
    ]), [])

    const statisticsPerDate = data.activityStatistics.perCreationDay.reduce((arr, { creationDate, perMLStatus }) => ([
        ...arr,
        {
            date: formatDateString(creationDate),
            ...perMLStatus.reduce((acc, item) => {
                acc[item.status] = item.count
                acc[`${item.status}PerType`] = item.perType.map(({ type, count }) => ({ slug: type.slug, count }))
                return acc
            }, {}),
        },
    ]), [])

    const statisticsPerDateWithEmptyDays = dates.map((date) => {
        const result = statisticsPerDate.find((stats) => formatDate(date) === stats.date)
        if (result) return result
        return {
            date: formatDate(date),
        }
    }).reverse()

    return {
        isFetching: loading,
        error,
        allProducts: data.allProducts,
        tenant: data.tenantsPaginated.nodes[0],
        focusedSettings: data.activityRelevancySettings,
        syncLogSummaries: data.syncLogSummaries,
        analyticsPerDay: analyticsPerDayWithEmptyDays,
        statisticsPerPartner,
        statisticsPerDate: statisticsPerDateWithEmptyDays,
        analyticsKeys,
        refetchQueries,
    }
}

export default useFetchTenantDetailsPageData
