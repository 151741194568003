/* eslint-disable max-classes-per-file */
// Remove this polyfill when the issue below is resolved
// https://github.com/jsdom/jsdom/issues/2156
class EventTargetPolyfill {
    constructor() {
        this.events = {}
    }

    addEventListener(type, listener) {
        if (typeof this.events[type] === 'undefined') {
            this.events[type] = []
        }
        this.events[type].push(listener)
    }

    removeEventListener(type, listener) {
        if (typeof this.events[type] !== 'undefined') {
            const index = this.events[type].indexOf(listener)
            if (index > -1) {
                this.events[type].splice(index, 1)
            }
        }
    }

    dispatchEvent(event) {
        const { type } = event
        if (typeof this.events[type] !== 'undefined') {
            this.events[type].forEach((listener) => {
                listener(event)
            })
        }
    }
}

const DEFAULT_DURATION = 5000

export class NotificationDispatcher extends EventTargetPolyfill {
    constructor(defaultDuration = DEFAULT_DURATION) {
        super()
        this.defaultDuration = defaultDuration
        this.notifications = []
    }

    createNotification(
        data,
        isPersistent = false,
        createdAt = Date.now(),
        duration = this.defaultDuration
    ) {
        return {
            data,
            isPersistent,
            createdAt,
            duration,
        }
    }

    removeNotification(notification) {
        const index = this.notifications.indexOf(notification)
        if (index !== -1) {
            this.notifications.splice(index, 1)
        }
        this.dispatchEvent(new CustomEvent('changed'))
        this.dispatchEvent(new CustomEvent('removed', { detail: notification }))
    }

    removeAllNotifications() {
        this.notifications = []
        this.dispatchEvent(new CustomEvent('changed'))
        this.dispatchEvent(new CustomEvent('removed'))
    }

    dispatchNotification(notification) {
        this.notifications.push(notification)
        if (!notification.isPersistent) {
            setTimeout(() => {
                this.removeNotification(notification)
            }, notification.duration)
        }
        this.dispatchEvent(new CustomEvent('changed'))
        this.dispatchEvent(new CustomEvent('added', { detail: notification }))
        return notification
    }

    dispatch(message, isPersistent, createdAt, duration) {
        const notification = this.createNotification(message, isPersistent, createdAt, duration)
        this.dispatchNotification(notification)
        return notification
    }

    subscribe(listener) {
        this.addEventListener('changed', () => listener([...this.notifications]))
        return () => {
            this.removeEventListener('changed', () => listener([...this.notifications]))
        }
    }
}

let notificationDispatcher = null
export const getNotificationDispatcher = () => {
    if (notificationDispatcher === null) {
        notificationDispatcher = new NotificationDispatcher()
        return notificationDispatcher
    }
    return notificationDispatcher
}
