import React from 'react'
import {
    Form, Button,
} from 'react-bootstrap'
import styled from 'styled-components'
import PageWithState from '../../layout/PageWithState'
import { ContentContainer, ContentTitle } from '../tenants/TenantsPage'

const ProfilePage = ({
    me,
    onLogout,
}) => (
    <PageWithState>
        <ContentContainer>
            <ContentTitle>Profile</ContentTitle>
            <Form>
                <Form.Label>Email address</Form.Label>
                <StyledInput readOnly size="sm" type="text" value={me && me.email} />
                <Form.Label>Name</Form.Label>
                <StyledInput readOnly size="sm" type="email" value={me && me.name} />
            </Form>
        </ContentContainer>
        <ContentContainer>
            <ContentTitle>Actions</ContentTitle>
            <Button size="sm" onClick={onLogout} variant="danger">Logout</Button>
        </ContentContainer>
    </PageWithState>
)

const StyledInput = styled(Form.Control)(({ theme }) => `
    margin-bottom: ${theme.spacingSmaller}rem;
`)

export default ProfilePage
