/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Table } from 'react-bootstrap'
import {
    PencilFill, PlusLg, TrashFill,
} from 'react-bootstrap-icons'
import useModalState from '../../../hooks/useModalState'
import useUpdateFocusedSettingsMutation from '../../../api/useUpdateFocusedSettingsMutation'
import useDeleteFocusedSettingsMutation from '../../../api/useDeleteFocusedSettingsMutation'
import { ContentTitle } from '../tenants/TenantsPage'
import AddFocusedSettingModal from './AddFocusedSettingModal'
import DeleteFocusedSettingModal from './DeleteFocusedSettingModal'
import UpdateFocusedSettingModal from './UpdateFocusedSettingModal'

export const PAGE_SIZE = 20

const FocusedSectionWithState = ({
    tenantGuid,
    focusedSettings,
    tenantUsers,
    refetchQueries,
}) => {
    const availableSettings = focusedSettings.filter(({ userId }) => (userId === null))
    const [updateFocusedSettings] = useUpdateFocusedSettingsMutation()
    const [deleteFocusedSettings] = useDeleteFocusedSettingsMutation()
    const addSettingModalState = useModalState()
    const deleteSettingModalState = useModalState()
    const updateSettingModalState = useModalState()

    const [isSubmitting, setIsSubmitting] = useState(false)

    const settingsPerUserAndType = [{ id: null, loginEmail: 'All users' }, ...tenantUsers].map(({ loginEmail, id }) => ({
        userId: id,
        loginEmail,
        settings: focusedSettings.filter(({ userId }) => userId === id),
    }))

    return (
        <>
            <ContentTitle>
                Focused activity settings
            </ContentTitle>
            {settingsPerUserAndType.map(({ userId, loginEmail, settings }) => (
                <React.Fragment key={userId}>
                    <TableTitle>
                        {loginEmail}
                    </TableTitle>
                    {settings.length !== 0 && (
                        <Table responsive="lg" hover borderless size="sm" key={userId}>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Label</th>
                                    <th>Value</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {settings.map((setting) => (
                                    <tr key={setting.key}>
                                        <td width={150}>
                                            {availableSettings.find(({ key }) => setting.key === key).typeSlug}
                                        </td>
                                        <td width="auto">{availableSettings.find(({ key }) => setting.key === key).label}</td>
                                        <td width={150}>{setting.value === ('true' || 'false') ? (
                                            setting.value === 'true' ? 'enabled' : 'disabled'
                                        ) : (
                                            setting.value
                                        )}
                                        </td>
                                        <td width={100}>
                                            <Button
                                                size="sm"
                                                variant="warning"
                                                onClick={() => updateSettingModalState.open({ setting })}
                                            >
                                                <PencilFill />
                                            </Button>
                                            {' '}
                                            {userId !== null && (
                                                <Button
                                                    size="sm"
                                                    variant="danger"
                                                    onClick={() => deleteSettingModalState.open({ setting })}
                                                >
                                                    <TrashFill />
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                    {userId !== null && (
                        <Button
                            onClick={() => addSettingModalState.open({ userId })}
                        >
                            <PlusLg />{' '}add setting
                        </Button>
                    )}
                </React.Fragment>

            ))}
            {addSettingModalState.isOpen && (
                <AddFocusedSettingModal
                    availableSettings={availableSettings}
                    onAddSetting={async (value) => {
                        const newSettings = [...focusedSettings, value]
                        setIsSubmitting(true)
                        await updateFocusedSettings({
                            variables: {
                                tenantGuid,
                                inputs: newSettings.map((setting) => ({
                                    key: setting.key,
                                    value: setting.value && setting.value.toString(),
                                    userId: setting.userId,
                                })),
                            },
                            refetchQueries,
                            awaitRefetchQueries: true,
                        })
                        setIsSubmitting(false)
                        addSettingModalState.close()
                    }}
                    onClose={() => addSettingModalState.close()}
                    isSubmitting={isSubmitting}
                    {...addSettingModalState.initialState}
                />
            )}
            {updateSettingModalState.isOpen && (
                <UpdateFocusedSettingModal
                    availableSettings={availableSettings}
                    onUpdateSetting={async (value) => {
                        const newSettings = [...focusedSettings, value]
                        setIsSubmitting(true)
                        await updateFocusedSettings({
                            variables: {
                                tenantGuid,
                                inputs: newSettings.map((setting) => ({
                                    key: setting.key,
                                    value: setting.value && setting.value.toString(),
                                    userId: setting.userId,
                                })),
                            },
                            refetchQueries,
                            awaitRefetchQueries: true,
                        })
                        setIsSubmitting(false)
                        updateSettingModalState.close()
                    }}
                    onClose={() => updateSettingModalState.close()}
                    isSubmitting={isSubmitting}
                    {...updateSettingModalState.initialState}
                />
            )}
            {deleteSettingModalState.isOpen && (
                <DeleteFocusedSettingModal
                    onDeleteSetting={async ({ userId, key }) => {
                        setIsSubmitting(true)
                        await deleteFocusedSettings({
                            variables: {
                                tenantGuid,
                                key,
                                userId,
                            },
                            refetchQueries,
                            awaitRefetchQueries: true,
                        })
                        setIsSubmitting(false)
                        deleteSettingModalState.close()
                    }}
                    isSubmitting={isSubmitting}
                    onClose={() => deleteSettingModalState.close()}
                    {...deleteSettingModalState.initialState}
                />
            )}
        </>
    )
}

const TableTitle = styled.h3(({ theme }) => `
    margin-top: ${theme.spacingSmaller}rem;
    margin-bottom: ${theme.spacingSmaller}rem;
`)

export default FocusedSectionWithState
