import React from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import styled from 'styled-components'
import { Check } from 'react-bootstrap-icons'
import useFocusedSettingForm from './useFocusedSettingForm'
import FocusedSettingForm from './FocusedSettingForm'

const AddFocusedSettingModal = ({
    onAddSetting,
    availableSettings,
    userId,
    onClose,
    isSubmitting,
}) => {
    const formState = useFocusedSettingForm({ userId })
    return (
        <Modal centered show keyboard="false" onHide={onClose}>
            <Container>
                <Form.Label>Add Setting</Form.Label>
                <FocusedSettingForm
                    availableSettings={availableSettings}
                    formState={formState}
                />
                <br />
                <div className="d-md-flex justify-content-md-end">
                    <Button variant="success" onClick={() => onAddSetting(formState.valuesToInput())} disabled={isSubmitting}>
                        <Check />Add
                    </Button>
                </div>
            </Container>
        </Modal>
    )
}

const Container = styled.div(({ theme }) => `
    padding: ${theme.spacing}rem;
`)

export default AddFocusedSettingModal
