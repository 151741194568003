import React from 'react'
import styled from 'styled-components'
import useNotifications from '../../hooks/useNotifications'
import NotificationToasterItem from './NotificationToasterItem'

const NotificationToaster = () => {
    const { notifications, onCloseNotification } = useNotifications()
    return (
        <Container>
            {notifications.map((notification) => (
                <NotificationToasterItem
                    key={notification.createdAt}
                    data={notification.data}
                    onCloseNotification={() => onCloseNotification(notification)}
                />
            ))}
        </Container>
    )
}

const Container = styled.div(({ theme }) => `
    position: fixed;
    width: 30rem;
    bottom: ${theme.spacingSmallest}rem;
    right: calc(50% - 15rem);
    z-index: 2;
`)

export default NotificationToaster
