import React from 'react'

const Logo = () => (
    <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="#edf2f7" stroke="#edf2f7" strokeLinecap="round" strokeWidth="1.2" transform="translate(0.5 0.5)">
            <polygon points="12 6 13.545 9.131 17 9.633 14.5 12.07 15.09 15.511 12 13.886 8.91 15.511 9.5 12.07 7 9.633 10.455 9.131 12 6" stroke="none" />
            <path d="M12,22S2,18,2,5L12,2,22,5C22,18,12,22,12,22Z" fill="none" stroke="#edf2f7" />
        </g>
    </svg>
)
export default Logo
