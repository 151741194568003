import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const TENANTS_QUERY = gql`
    query tenants (
        $currentPage: Int!,
        $pageSize: Int!
    ) {
        tenantsPaginated (
            currentPage: $currentPage,
            pageSize: $pageSize
        ) {
            meta {
                currentPage
                lastPage
                pageSize
                totalCount
            }
            nodes {
                id
                guid
                name
                dBUpdateStatusSlug
            }
        }
    }
`

const useFetchTenantsPageData = (options) => {
    const variables = {
        ...options,
    }
    const {
        data = {},
        error,
        loading,
    } = useQuery(TENANTS_QUERY, {
        variables,
        fetchPolicy: 'cache-and-network',
    })

    if (loading) {
        return {
            isFetching: loading,
            error,
            tenants: [],
        }
    }

    return {
        isFetching: loading,
        error,
        tenants: data.tenantsPaginated.nodes,
        meta: data.tenantsPaginated.meta,
    }
}

export default useFetchTenantsPageData
