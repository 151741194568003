import { createGlobalStyle, css } from 'styled-components'

const AppGlobalStyles = createGlobalStyle`
    ${({ theme }) => css`
        *,
        *:after,
        *:before {
            box-sizing: inherit;
            border-width: 0;
            border-style: solid;
            border-color: ${theme.defaultBorderColor};
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
        *:focus {
            // outline: 5px auto ${theme.colorPrimaryDark};
        }

        html {
            box-sizing: border-box;
        }

        body {
            background-color: ${theme.defaultBackgroundColor};
        }

        a {
            text-decoration: none;
            cursor: pointer;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0;
        }

        h1 {
            font-size: ${theme.fontSizeLarge}rem;
        }

        h2 {
            font-size: ${theme.fontSize}rem;
        }

        h3 {
            font-size: ${theme.fontSizeSmall}rem;
        }

        img {
            max-width: 100%;
        }
        
        svg {
            vertical-align: middle;
        }

        .table td {
            padding: ${theme.spacingMinimum};
            margin: ${theme.spacingMinimum};
            vertical-align: middle;
            white-space: nowrap; 
            font-size: ${theme.fontSizeSmaller}rem;
        }

        .table th {
            padding: ${theme.spacingMinimum};
            margin: ${theme.spacingMinimum};
            vertical-align: middle;
            white-space: nowrap; 
            font-size: ${theme.fontSizeSmaller}rem;
        }

        .pagination-sm .page-link {
            padding: ${theme.spacingMinimum}rem ${theme.spacingSmallest}rem;
            font-size: ${theme.fontSizeSmaller}rem;
            color: ${theme.colorBlueDark};

        }

        .page-item.active .page-link {
            color: ${theme.colorWhite};
            background-color: ${theme.colorBlueDark};
            border-color: ${theme.colorBlueDark};
        }
    `}
`

export default AppGlobalStyles
