import React, { useMemo } from 'react'
import { whereId } from '../../../util/tools'
import TenantDetailsSubscriptionSection from './TenantDetailsSubscriptionSection'
import useUpdateSubscriptionFormState from './useUpdateSubscriptionFormState'

const TenantDetailsSubscriptionSectionWithState = ({
    subscription,
    allProducts,
    onUpdateSubscription,
    ...props
}) => {

    const productPlans = useMemo(() => allProducts.flatMap((product) => (
        product.plans.map((plan) => ({
            ...plan,
            name: `${product.slug} - ${plan.name}`,
        }))
    )), [allProducts])
    const formState = useUpdateSubscriptionFormState(subscription)

    const handleSubmit = () => {
        const input = formState.valuesToInput()
        onUpdateSubscription(input)
    }
    const currentProductPlan = productPlans.find(whereId(formState.getValue('planId')))
    return (
        <TenantDetailsSubscriptionSection
            formState={formState}
            productPlans={productPlans}
            currentProductPlan={currentProductPlan}
            subscription={subscription}
            onSubmit={handleSubmit}
            {...props}
        />
    )
}

export default TenantDetailsSubscriptionSectionWithState
