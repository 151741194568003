import React from 'react'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import * as theme from '../styles/theme'

const ThemeProvider = ({
    children,
}) => (
    <StyledComponentsThemeProvider theme={theme}>
        {children}
    </StyledComponentsThemeProvider>
)

export default ThemeProvider
