import React from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { Button, Col, Form, Row } from 'react-bootstrap'

const FRAGMENT = gql`
    fragment TenantDetailsSubscriptionSection on SubscriptionProduct {
        id
        slug
        plans {
            id
            name
            priceInCents
        }
    }
`

const TenantDetailsSubscriptionSection = ({
    formState,
    productPlans,
    currentProductPlan,
    stripeCustomerId,
    subscription,
    currentPaymentMethod,
    onSubmit,
}) => {

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault()
                onSubmit()
            }}
        >
            <Form.Group className="mb-3">
                <Form.Label>Customer ID</Form.Label>
                <Form.Control disabled size="sm" type="text" value={stripeCustomerId} />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Subscription status</Form.Label>
                <Form.Control disabled size="sm" type="text" value={subscription.stripeStatus} />
            </Form.Group>
            <Row className="mb-5">
                <Col>
                    <Form.Label>Payment method</Form.Label>
                    <Form.Control
                        disabled
                        size="sm"
                        type="text"
                        value={currentPaymentMethod ? currentPaymentMethod.type : '-'}
                    />
                </Col>
                <Col>
                    <Form.Label>Payment method createdOn</Form.Label>
                    <Form.Control
                        disabled
                        size="sm"
                        type="text"
                        value={currentPaymentMethod ? currentPaymentMethod.created : '-'}
                    />
                </Col>
                <Col>
                    <Form.Label>Payment method expiration</Form.Label>
                    <Form.Control
                        disabled
                        size="sm"
                        type="text"
                        value={
                            currentPaymentMethod
                                ? `${currentPaymentMethod.expirationYear ?? ''}-${currentPaymentMethod.expirationMonth ?? ''}`
                                : '-'
                        }
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Label>Trial end date</Form.Label>
                    <Form.Control
                        size="sm"
                        type="date"
                        {...formState.getNativeInputProps('trialEnd')}
                    />
                </Col>
                <Col>
                    <Form.Label>Quantity</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        {...formState.getNativeInputProps('quantity')}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Label>Product</Form.Label>
                    <Form.Control
                        as="select"
                        size="sm"
                        {...formState.getNativeInputProps('planId')}
                        onChange={(e) => formState.handleChange('planId', parseInt(e.target.value))}
                        aria-label="Product select"
                    >
                        {productPlans.map((productPlan) => (
                            <option key={productPlan.id} value={productPlan.id} className="capitalize">
                                {productPlan.name}
                            </option>
                        ))}
                    </Form.Control>
                </Col>
                <Col>
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                        disabled
                        size="sm"
                        type="text"
                        value={currentProductPlan.priceInCents / 100}
                    />
                </Col>
                <Col>
                    <Form.Label />
                    <Form.Check className='mt-2' type='checkbox' id={`check-api-'checkbox'`}>
                        <CustomCheckbox
                            checked={formState.values.allowSecibNeoReporting}
                            onChange={() => formState.handleChange('allowSecibNeoReporting', !formState.values.allowSecibNeoReporting)}
                            className='mt-2 w'
                            type='checkbox'
                        />
                        <Form.Check.Label className='ml-2' htmlFor={`check-api-'checkbox'`}>Reporting layer available</Form.Check.Label>
                    </Form.Check>
                    
                </Col>
            </Row>
            <Button variant="primary" type="submit"> 
                Update
            </Button>
        </Form>
    )
}

const CustomCheckbox = styled(Form.Check.Input)`
    transform: scale(1.5); /* Adjust the scale factor as needed */
    cursor:pointer;
    margin-right : 5px
`;

TenantDetailsSubscriptionSection.FRAGMENT = FRAGMENT

export default TenantDetailsSubscriptionSection
