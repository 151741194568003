import React from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import styled from 'styled-components'
import { Check } from 'react-bootstrap-icons'
import useFocusedSettingForm from './useFocusedSettingForm'
import FocusedSettingForm from './FocusedSettingForm'

const UpdateFocusedSettingModal = ({
    setting,
    availableSettings,
    onUpdateSetting,
    onClose,
    isSubmitting,
}) => {
    const formState = useFocusedSettingForm(setting)
    return (
        <Modal centered show keyboard="false" onHide={onClose}>
            <Container>
                <Form.Label>Update Setting</Form.Label>
                <FocusedSettingForm
                    availableSettings={availableSettings}
                    formState={formState}
                    keyIsFixed
                />
                <div className="d-md-flex justify-content-md-end">
                    <Button variant="success" onClick={() => onUpdateSetting(formState.valuesToInput())} disabled={isSubmitting}>
                        <Check />Update
                    </Button>
                </div>
            </Container>
        </Modal>
    )
}

const Container = styled.div(({ theme }) => `
    padding: ${theme.spacing}rem;
`)

export default UpdateFocusedSettingModal
