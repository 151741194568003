import { differenceInCalendarDays } from 'date-fns'
import useFormState, { createFormValidation } from 'use-form-state'
import { formatDateString } from '../../../util/dates'

const toInitialValues = ({
    quantity,
    trialEnd,
    plan,
    allowSecibNeoReporting,
}) => ({
    quantity,
    trialEnd: formatDateString(trialEnd, 'yyyy-MM-dd'),
    planId: plan.id,
    allowSecibNeoReporting,
})

const validation = createFormValidation([{
    path: 'trialEnd',
    validate: (value) => new Date(value) > new Date(),
    message: 'Date must be after now',
}])

const valuesToInput = ({
    quantity,
    trialEnd,
    planId,
    allowSecibNeoReporting,
}) => {
    const differenceInDays = differenceInCalendarDays(new Date(trialEnd), new Date())
    const newTrialInDays = differenceInDays > 0 ? differenceInDays : 0
    return {
        planId,
        quantity: parseInt(quantity),
        newTrialInDays,
        allowSecibNeoReporting,
    }
}

const useUpdateSubscriptionFormState = (initialValues) => (
    useFormState(toInitialValues(initialValues), { valuesToInput, validation })
)

export default useUpdateSubscriptionFormState
