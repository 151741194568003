import React from 'react'
import styled from 'styled-components'
import { SIDEBAR_WIDTH } from './Sidebar'
import { TOPBAR_HEIGHT } from './Topbar'

const Content = ({ children }) => (
    <Container>
        {children}
    </Container>
)

const Container = styled.main(({ theme }) => `
    padding-top: calc(${TOPBAR_HEIGHT}rem + ${theme.spacingSmall}rem);
    padding-left: calc(${SIDEBAR_WIDTH}rem + ${theme.spacingSmall}rem);
    padding-right: ${theme.spacingSmall}rem;
`)

export default Content
