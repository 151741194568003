import React from 'react'
import { useNavigate } from '@reach/router'
import { useApolloClient } from '@apollo/client'
import handleAuthorizationUrl from '../../../util/authorization'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import login from '../../../api/login'
import useAppState from '../../../hooks/useAppState'
import LoadingPage from '../loading/LoadingPage'

const {
    REACT_APP_ACTIVE_DIRECTORY_REDIRECT_URI: CALLBACK_URI,
} = process.env

const AuthenticatingPage = ({
    location,
}) => {
    const navigate = useNavigate()
    const apolloClient = useApolloClient()
    const { search } = location
    const appState = useAppState()
    useAsyncEffect(async () => {
        try {
            const { code } = handleAuthorizationUrl(search)
            const {
                accessToken,
                refreshToken,
                me,
            } = await login(apolloClient, code, CALLBACK_URI)
            await appState.login(accessToken, refreshToken, me)
            navigate('/profile')
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e)
        }
    }, [search])
    return <LoadingPage />
}

export default AuthenticatingPage
