import React from 'react'

const DashboardIcon = () => (
    <svg height="32" width="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <g strokeLinecap="round" strokeWidth="1.2" transform="translate(0.5 0.5)">
            <line fill="none" x1="16" x2="16" y1="4" y2="8" />
            <line fill="none" x1="26.607" x2="23.778" y1="8.393" y2="11.222" />
            <line fill="none" x1="31" x2="27" y1="19" y2="19" />
            <line fill="none" x1="1" x2="5" y1="19" y2="19" />
            <line fill="none" x1="13.879" x2="8" y1="16.879" y2="11" />
            <circle cx="16" cy="19" fill="none" r="3" />
            <path d="M27.98,28 c1.888-2.509,3.02-5.618,3.02-9c0-8.284-6.716-15-15-15C7.716,4,1,10.716,1,19c0,3.382,1.133,6.491,3.02,9H27.98z" fill="none" />
        </g>
    </svg>
)

export default DashboardIcon
