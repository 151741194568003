import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'

const removeTenantMutation = gql`
    mutation removeTenant(
        $tenantGuid: String!,
        $tenantName: String!
    ) {
        removeTenant(
            tenantGuid: $tenantGuid,
            tenantName: $tenantName
        )
    }
`

export default function useDeleteTenantMutation() {
    return useMutation(removeTenantMutation)
}
