import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import styled from 'styled-components'

const DeleteFocusedSettingModal = ({
    onDeleteSetting,
    setting,
    onClose,
    isSubmitting,
}) => (
    <Modal centered show size="sm" onHide={onClose}>
        <Container>
            <Message>Are you sure you want to delete the personal preference?</Message>
            <Button
                variant="danger"
                onClick={() => {
                    onDeleteSetting(setting)
                }}
                disabled={isSubmitting}
            >
                Delete
            </Button>
        </Container>
    </Modal>
)

const Container = styled.div(({ theme }) => `
    padding: ${theme.spacing}rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`)

const Message = styled.div`
    margin-bottom: 1rem;
`

export default DeleteFocusedSettingModal
