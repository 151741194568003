import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createHttpLink } from '@apollo/client/link/http'

const { REACT_APP_GRAPHQL_URI: GRAPHQL_URI } = process.env

const httpLink = createHttpLink({
    uri: GRAPHQL_URI,
    credentials: 'include',
})

export default function createSimpleApolloClient() {
    return new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache(),
    })
}
