import React from 'react'
import useAppState from '../../../hooks/useAppState'
import ProfilePage from './ProfilePage'

const ProfilePageWithState = () => {
    const { me, logout } = useAppState()
    return (
        <ProfilePage
            me={me}
            onLogout={logout}
        />
    )
}

export default ProfilePageWithState
