/* eslint-disable react/no-unused-state */
import React from 'react'
import * as storage from './util/storage'
import { loadAndValidateTokens } from './util/auth'
import AppStateProvider from './providers/AppStateProvider'
import App from './App'
import getInitialData from './api/getInitialData'

export default class AppWithState extends React.Component {
    constructor(props) {
        super(props)

        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)
        this.refresh = this.refresh.bind(this)

        this.state = {
            isAuthenticated: false,
            me: null,
            isLoadingInitialData: true,
            login: this.login,
            logout: this.logout,
            refresh: this.refresh,
        }
    }

    async componentDidMount() {
        const { apolloClient } = this.props
        try {
            const { isAuthenticated } = await this.loadTokens()
            await apolloClient.resetStore()
            if (isAuthenticated) {
                await this.fetchInitialData()
            }
        } catch {
            this.logout()
        }
    }

    async fetchInitialData() {
        const { apolloClient } = this.props
        const { me } = await getInitialData(apolloClient)

        this.setState({
            isAuthenticated: true,
            isLoadingInitialData: false,
            me,
        })
    }

    async loadTokens() {
        const { accessToken } = await loadAndValidateTokens()
        const isAuthenticated = accessToken !== null
        this.setState((prevState) => ({
            ...prevState,
            isAuthenticated,
            isLoadingTokens: false,
        }))
        return { isAuthenticated }
    }

    async login(accessToken, refreshToken, me) {
        await storage.setAccessToken(accessToken)
        await storage.setRefreshToken(refreshToken)
        this.setState((prevState) => ({
            ...prevState,
            isAuthenticated: true,
            me,
            isLoadingInitialData: false,
        }))
    }

    async logout() {
        await Promise.all([
            storage.removeAccessToken(),
            storage.removeRefreshToken(),
        ])
        this.setState((prevState) => ({
            ...prevState,
            me: null,
            isAuthenticated: false,
            isLoadingTokens: false,
        }))
    }

    async refresh() {
        const { apolloClient } = this.props
        this.setState((prevState) => ({
            ...prevState,
            me: null,
            isAuthenticated: false,
            isLoadingTokens: false,
        }))
        await this.loadTokens()
        await apolloClient.resetStore()
    }

    render() {
        const {
            notificationDispatcher,
            history,
            apolloClient,
        } = this.props
        return (
            <AppStateProvider value={this.state}>
                <App
                    history={history}
                    apolloClient={apolloClient}
                    notificationDispatcher={notificationDispatcher}
                />
            </AppStateProvider>
        )
    }
}
