/* eslint-disable no-alert */
import { useApolloClient } from '@apollo/client'
import React, { useState } from 'react'
import resetOnboarding from '../../../api/resetOnboarding'
import uploadContactsFile from '../../../api/uploadContactsFile'
import uploadCompaniesFile from '../../../api/uploadCompaniesFile'
import uploadProjectsFile from '../../../api/uploadProjectsFile'
import TenantDetailsPage from './TenantDetailsPage'
import useFetchTenantDetailsPageData from './useFetchTenantDetailsPageData'
import revertDataImport from '../../../api/revertDataImport'
import downloadContactsFile from '../../../api/downloadContactsFile'
import downloadCompaniesFile from '../../../api/downloadCompaniesFile'
import downloadProjectsFile from '../../../api/downloadProjectsFile'
import {
    downloadFromResponse, sortBy,
} from '../../../util/tools'
import useNotifications from '../../../hooks/useNotifications'
import PageWithState from '../../layout/PageWithState'
import { ContentContainer } from '../tenants/TenantsPage'
import { Spinner } from 'react-bootstrap'
import useDeleteTenantMutation from '../../../api/useDeleteTenantMutation'
import { useNavigate } from '@reach/router'
import useUpdateSubscriptionMutation from '../../../api/useUpdateSubscriptionMutation'

export const PAGE_SIZE = 20

const TenantDetailsPageWithState = ({
    tenantGuid,
    ...props
}) => {
    const {
        tenant,
        focusedSettings,
        syncLogSummaries,
        analyticsPerDay,
        statisticsPerPartner,
        statisticsPerDate,
        analyticsKeys,
        isFetching,
        refetchQueries,
        allProducts,
    } = useFetchTenantDetailsPageData(tenantGuid)
    const apolloClient = useApolloClient()
    const navigate = useNavigate()

    const [isUploadingContacts, setIsUploadingContacts] = useState(false)
    const [isUploadingCompanies, setIsUploadingCompanies] = useState(false)
    const [isUploadingProjects, setIsUploadingProjects] = useState(false)
    const [isResettingAccountState, setIsResettingAccountState] = useState(false)
    const [isRevertingImport, setIsRevertingImport] = useState(false)

    const { dispatchError, dispatchSuccesss } = useNotifications()

    const [deleteTenant] = useDeleteTenantMutation()
    const [updateSubscription] = useUpdateSubscriptionMutation()

    if (isFetching) {
        return (
            <PageWithState>
                <ContentContainer fluid>
                    <Spinner animation="border" variant="secondary" />
                </ContentContainer>
            </PageWithState>
        )
    }

    const handleUpdateSubscription = async (input) => {
        try {
            await updateSubscription({
                variables: {
                    tenantGuid: tenant.guid,
                    input,
                },
                refetchQueries
            })
        } catch (error) {
            dispatchError(error)
        }
    }

    return (
        <TenantDetailsPage
            tenant={tenant}
            allProducts={allProducts}
            focusedSettings={focusedSettings}
            syncLogSummaries={syncLogSummaries.sort(sortBy('key'))}
            analyticsPerDay={analyticsPerDay.sort(sortBy('date'))}
            statisticsPerPartner={statisticsPerPartner}
            statisticsPerDate={statisticsPerDate}
            analyticsKeys={analyticsKeys}
            isFetching={isFetching}
            refetchQueries={refetchQueries}
            isUploadingContacts={isUploadingContacts}
            isUploadingCompanies={isUploadingCompanies}
            isUploadingProjects={isUploadingProjects}
            isResettingAccountState={isResettingAccountState}
            isRevertingImport={isRevertingImport}
            onResetAccountState={async (userId) => {
                setIsResettingAccountState(userId)
                try {
                    await resetOnboarding(apolloClient, userId, tenant.id, { refetchQueries })
                } catch (error) {
                    dispatchError(error)
                } finally {
                    setIsResettingAccountState(false)
                }
            }}
            onUploadContacts={async (guid, file) => {
                setIsUploadingContacts(true)
                try {
                    const response = await uploadContactsFile(guid, file)
                    if (response.ok) {
                        dispatchSuccesss('contacts upload succes, please refresh the page to see the import history')
                    } else {
                        dispatchError('contact upload failed')
                    }
                } catch (error) {
                    dispatchError(error)
                } finally {
                    setIsUploadingContacts(false)
                }
            }}
            onUploadCompanies={async (guid, file) => {
                setIsUploadingCompanies(true)
                try {
                    const response = await uploadCompaniesFile(guid, file)
                    if (response.ok) {
                        dispatchSuccesss('companies upload succes, please refresh the page to see the import history')
                    } else {
                        dispatchError('companies upload failed')
                    }
                } catch (error) {
                    dispatchError(error)
                } finally {
                    setIsUploadingCompanies(false)
                }
            }}
            onUploadProjects={async (guid, file) => {
                setIsUploadingProjects(true)
                try {
                    const response = await uploadProjectsFile(guid, file)
                    if (response.ok) {
                        dispatchSuccesss('projects upload succes, please refresh the page to see the import history')
                    } else {
                        dispatchError('projects upload failed')
                    }
                } catch (error) {
                    dispatchError(error)
                } finally {
                    setIsUploadingProjects(false)
                }
            }}
            onRevertImport={async (importGuid) => {
                setIsRevertingImport(importGuid)
                try {
                    await revertDataImport(apolloClient, importGuid, { refetchQueries })
                } catch (error) {
                    dispatchError(error)
                } finally {
                    setIsRevertingImport(false)
                }
            }}
            onDownloadContactsTemplate={async () => {
                try {
                    const response = await downloadContactsFile()
                    downloadFromResponse(response)
                } catch (error) {
                    dispatchError(error)
                }
            }}
            onDownloadCompaniesTemplate={async () => {
                try {
                    const response = await downloadCompaniesFile()
                    downloadFromResponse(response)
                } catch (error) {
                    dispatchError(error)
                }
            }}
            onDownloadProjectsTemplate={async () => {
                try {
                    const response = await downloadProjectsFile()
                    downloadFromResponse(response)
                } catch (error) {
                    dispatchError(error)
                }
            }}
            onDeleteTenant={async () => {
                try {
                    deleteTenant({
                        variables: {
                            tenantGuid: tenant.guid,
                            tenantName: tenant.name,
                        },
                        onUpdate: () => navigate('/tenants')
                    })
                } catch (error) {
                    dispatchError(error)
                }
            }}
            onUpdateSubscription={handleUpdateSubscription}
            {...props}
        />
    )
}

export default TenantDetailsPageWithState
