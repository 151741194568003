import { useEffect, useState, useContext } from 'react'
import { NotificationsContext } from '../providers/NotificationsProvider'

const useNotifications = () => {
    const notificationDispatcher = useContext(NotificationsContext)
    const [notifications, setNotifications] = useState([])
    useEffect(() => (
        notificationDispatcher.subscribe((newNotifications) => {
            setNotifications(newNotifications)
        })
    ), [notificationDispatcher])

    const dispatchSuccesss = (message, isPersistent) => (
        notificationDispatcher.dispatch({
            variant: 'success',
            message,
        }, isPersistent)
    )

    const dispatchError = (message, isPersistent) => (
        notificationDispatcher.dispatch({
            variant: 'danger',
            message,
        }, isPersistent)
    )

    const dispatchWarning = (message, isPersistent) => (
        notificationDispatcher.dispatch({
            variant: 'warning',
            message,
        }, isPersistent)
    )

    const dispatchMessage = (message, isPersistent) => (
        notificationDispatcher.dispatch({
            message,
        }, isPersistent)
    )

    return {
        notifications,
        dispatchError,
        dispatchWarning,
        dispatchSuccesss,
        dispatchMessage,
        dispatch: (data) => notificationDispatcher.dispatch(data),
        onCloseNotification: (notification) => (
            notificationDispatcher.removeNotification(notification)
        ),
    }
}

export default useNotifications
