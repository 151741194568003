import React from 'react'
import { Form } from 'react-bootstrap'

const FocusedSettingForm = ({
    availableSettings,
    formState,
    keyIsFixed,
}) => {
    const { getNativeInputProps, handleChangeSetting } = formState
    return (
        <>
            <Form.Control
                as="select"
                {...getNativeInputProps('key')}
                onChange={(e) => handleChangeSetting(availableSettings.find(({ key }) => key === e.target.value))}
                disabled={keyIsFixed}
            >
                {availableSettings.map(({ key, label }) => (
                    <option key={key} value={key}>{label}</option>
                ))}
            </Form.Control>
            <Form.Label>Example</Form.Label>
            <Form.Control
                type="text"
                {...getNativeInputProps('example')}
                value={availableSettings.find(({ key }) => key === formState.values.key).example}
                disabled
            />
            <Form.Label>Value</Form.Label>
            {availableSettings.find(({ key }) => key === formState.values.key).dataTypeSlug === 'bool' ? (
                <Form.Check
                    {...getNativeInputProps('value')}
                    checked={formState.values.value}
                />
            ) : (
                <Form.Control
                    type="text"
                    {...getNativeInputProps('value')}
                />
            )}
        </>
    )
}

export default FocusedSettingForm
