/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import React, { useState } from 'react'
import ActivitiesSection from './ActivitiesSection'

const ActivitiesSectionWithState = ({
    statisticsPerPartner,
    statisticsPerDate,
}) => {
    const [view, setView] = useState('date')
    return (
        <ActivitiesSection
            view={view}
            data={view === 'date' ? statisticsPerDate : statisticsPerPartner}
            keys={['none', 'pending', 'processed']}
            handleChangeView={(e) => {
                if (view === 'date') {
                    setView(e.indexValue)
                } else {
                    setView('date')
                }
            }}
        />
    )
}

export default ActivitiesSectionWithState
