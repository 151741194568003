import gql from 'graphql-tag'

export const INITIAL_DATA_QUERY = gql`
    query initialDataQuery {
        me {
            name
            email
        }
    }
`

export default async function getInitialData(apolloClient, options) {
    const query = INITIAL_DATA_QUERY
    const response = await apolloClient.query({
        query,
        ...options,
    })
    return response.data
}
