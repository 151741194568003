import { useNavigate } from '@reach/router'
import React from 'react'
import {
    Badge,
    Button,
    Container,
    Form,
    Pagination,
    Spinner,
    Table,
} from 'react-bootstrap'
import styled from 'styled-components'
import PageWithState from '../../layout/PageWithState'

const TenantsPage = ({
    isFetching,
    tenants,
    meta,
    currentPage,
    onChangeCurrentPage,
}) => {
    const navigate = useNavigate()
    let pages = 0
    const items = []
    if (!isFetching) {
        pages = Math.ceil(meta.totalCount / meta.pageSize)
    }
    for (let i = 1; i <= pages; i += 1) {
        items.push(
            <Pagination.Item
                key={i}
                active={i === currentPage}
                onClick={() => onChangeCurrentPage(i)}
            >
                {i}
            </Pagination.Item>
        )
    }
    if (isFetching) {
        return (
            <PageWithState>
                <ContentContainer fluid>
                    <Spinner animation="border" variant="secondary" />
                </ContentContainer>
            </PageWithState>
        )
    }
    return (
        <PageWithState>
            <ContentContainer fluid>
                <ContentTitle>Tenants</ContentTitle>
                <Table responsive="lg" hover borderless size="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>GUID</th>
                            <th className="text-center">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tenants.map((tenant, index) => (
                            <tr key={tenant.id}>
                                <td>{meta.pageSize * (currentPage - 1) + index + 1}</td>
                                <td>{tenant.name}</td>
                                <td>{tenant.guid}</td>
                                <td className="text-center">
                                    {(() => {
                                        switch (tenant.dBUpdateStatusSlug) {
                                            case 'new': return <Badge bg="info" text="light">new</Badge>
                                            case 'ready': return <Badge bg="success" text="light">ready</Badge>
                                            case 'migrating': return <Badge bg="warning" text="light">migrating</Badge>
                                            case 'processing': return <Badge bg="warning" text="light">processing</Badge>
                                            case 'failedmigrating': return <Badge bg="danger" text="light">failedmigrating</Badge>
                                            case 'failedprocessing': return <Badge bg="danger" text="light">failedprocessing</Badge>
                                            default: return null
                                        }
                                    })()}
                                </td>
                                <td className="text-right">
                                    <Button size="sm" variant="light" onClick={() => navigate(`/tenant/${tenant.guid}`)}>details</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <TableNavigation>
                    <Pagination size="sm">{items}</Pagination>
                </TableNavigation>
            </ContentContainer>
        </PageWithState>
    )
}

export const ContentContainer = styled(Container)(({ theme }) => `
    background-color: white;
    border-radius: 0.25rem;
    padding: ${theme.spacingSmall}rem;
    margin-bottom: ${theme.spacingSmall}rem;
`)

export const ContentTitle = styled.h2(({ theme }) => `
    padding-bottom: ${theme.spacingSmallest}rem;
    margin-bottom: ${theme.spacingSmaller}rem;
    border-bottom: 1px solid ${theme.colorGreyLighter};
`)

export const TableNavigation = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const TableItemCountSelect = styled(Form.Control)(({ theme }) => `
    font-size: ${theme.fontSizeSmaller}rem;
    margin-right: ${theme.spacingSmallest}rem;
    color: ${theme.colorBlueDark};
    cursor: pointer;
`)

export const Active = ({ label }) => <Badge bg="success" text="light">{ label || 'Active'}</Badge>
export const Disabled = ({ label }) => <Badge bg="danger" text="light">{ label || 'Disabled'}</Badge>

export default TenantsPage
