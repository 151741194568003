import React from 'react'
import { Router } from '@reach/router'
import AddTenantPageWithState from './components/pages/add-tenant/AddTenantPageWithState'
import LoginPage from './components/pages/login/LoginPage'
import AuthenticatingPage from './components/pages/authenticating/AuthenticatingPage'
import ProfilePageWithState from './components/pages/profile/ProfilePageWithState'
import LoadingPage from './components/pages/loading/LoadingPage'
import TenantsPageWithState from './components/pages/tenants/TenantsPageWithState'
import TenantDetailsPageWithState from './components/pages/tenant-details/TenantDetailsPageWithState'
import DashboardPageWithState from './components/pages/dashboard/DashboardPageWithState'

const AppRouter = ({
    isAuthenticated,
    isLoadingInitialData,
    ...props
}) => {
    if (!isAuthenticated) {
        return (
            <Router>
                <LoginPage
                    default
                    path="/login"
                    {...props}
                />
                <AuthenticatingPage
                    path="/authenticate"
                    {...props}
                />
            </Router>
        )
    }
    if (isLoadingInitialData) {
        return (
            <Router>
                <LoadingPage path="/loading" default />
            </Router>
        )
    }
    return (
        <Router>
            <DashboardPageWithState
                path="/dashboard"
                default
                {...props}
            />
            <AddTenantPageWithState
                path="/add-tenant"
                {...props}
            />
            <ProfilePageWithState
                path="/profile"
                {...props}
            />
            <TenantsPageWithState
                path="/tenants"
                {...props}
            />
            <TenantDetailsPageWithState
                path="/tenant/:tenantGuid/*"
                {...props}
            />
        </Router>
    )
}

export default AppRouter
